import { DropDown } from "../ui/dropdowns/DropDown"


export const ChooseCourse = ({courses, onChange, handleClick, setCourse}) => {
  return(
    <DropDown id = 'coursePicker' label="Course" courses={courses} handleClick={handleClick}>
      <div className="space-y-2">
        {courses.map((course) => (
          <span
            key={course}
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white rounded-lg"
            onClick={() => {
                setCourse(course)
                handleClick()
              }
            } 
          >
            {course}
          </span>
        ))}
      </div> 
    </DropDown>
  )
}

export const ChooseExercise = ({exercises, onChange, handleClick, setExercise}) => {
  return(
    <DropDown id = 'exercisePicker' label="Exercise" exercises={exercises} handleClick={handleClick}>
      <div className="space-y-2">
        {exercises.map((exercise) => (
          <span
            key={exercise}
            className="block px-4 py-2 hover:bg-blue-500 hover:text-white rounded-lg"
            onClick={() => {
              setExercise(exercise)
              handleClick()
              }
            }
          >
            {exercise}
          </span>
        ))}
      </div> 
    </DropDown>
  )
}