import Questions from './components/Questions.js';


const App = () =>  {  
  return (
    <div className='w-full bg-[#EEE8E8] h-full min-h-screen'>
      <Questions/>
    </div>
  );
}

export default App;
