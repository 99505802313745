import { NewQuestion } from "./NewQuestion";
import { useState } from "react";

const Questions = () =>  {
  const [newQuestion, setNewQuestion] = useState(true);
  return (
    <div className="flex items-center flex-col justify-center pt-3">
      {newQuestion && <NewQuestion setNewQuestion={setNewQuestion}/>}

    </div>
  );
}


export default Questions;